import React from 'react'
import { graphql, Link } from 'gatsby'
import * as styles from '../styles/blog/BlogDetail.module.css'

const BlogList = ({ blogList }) => {
  return (
    <div className={styles.right_content}>
      <h2>他の情報</h2>
      <div className={styles.blog_content_wrap}>
        {blogList.length !== 0 &&
          blogList.slice(0, 4).map((value, index) => {
            const blogValue = value.node
            return (
              <Link
                to={`/blog-details/${blogValue.id}`}
                key={index}
                className={styles.right_content_link}
              >
                <img alt={blogValue.title} src={blogValue.image.url} />
                <p>{blogValue.title}</p>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default BlogList
