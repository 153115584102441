import React from 'react'
import { graphql } from 'gatsby'
import * as styles from '../../styles/blog/BlogDetail.module.css'
import BlogList from '../../components/BlogList'

const BlogDetail = ({ data, location }) => {
  const currentBlogData = data?.microcmsBlog
  const allBlogData = data?.allMicrocmsBlog?.edges
  return (
    <div className={styles.wrap}>
      <div className={styles.left_content}>
        <h1>{currentBlogData.title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: `${currentBlogData.content}`,
          }}
        />
      </div>
      <BlogList blogList={allBlogData} />
    </div>
  )
}

export default BlogDetail

export const query = graphql`
  query ($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      title
      content
      image {
        url
      }
    }
    allMicrocmsBlog {
      edges {
        node {
          id
          title
          image {
            url
          }
        }
      }
    }
  }
`
